import React from "react";
import { Link } from "react-router-dom";
import useCurrency from "../../hooks/useCurrency.js";
import imageCourse from "../assets/icon_course.svg";
import imageExamSimulator from "../assets/icon_exam_simulator.svg";
import imageTraining from "../assets/icon_training.svg";
import imageVoucher from "../assets/icon_voucher.svg";
import styles from "./styles.module.css";

const CourseTrainingCard = ({
  id,
  thumbnail,
  title,
  category,
  price,
  level,
  rating,
  type,
  courseType
}) => {
  const { currency } = useCurrency();

  const getImage = () => {
    switch (type) {
      case "training":
        return imageTraining;
      case "course":
        if (courseType === "exam-simulator") return imageExamSimulator;
        return imageCourse;
      case "voucher":
        return imageVoucher;
      default:
        return imageTraining;
    }
  };

  return (
    <Link to={`/${type}/${id}`} key={id}>
      <div key={id} className={styles.container}>
        <img
          src={`${process.env.REACT_APP_API}${thumbnail}`}
          className={styles.image}
          alt=""
        />

        <div className={styles.content}>
          <div className={styles.text}>
            <p>{category}</p>

            <p>
              {price} {currency?.code}
            </p>
          </div>

          <div className={styles.title}>
            <p>{level}</p>
            <p>
              {courseType &&
                courseType === "exam-simulator" &&
                "Exam simulator: "}
              {title}
            </p>
          </div>

          {/* <div className={styles.rating}>
            <span>⭐ {rating} (750)</span>
            <div className={styles.stars}>
              <div className={styles.avatarGroup}>
                <img
                  src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
                  alt=""
                />
                <img
                  src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
                  alt=""
                />
                <img
                  src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
                  alt=""
                />
              </div>
              <span>3k+</span>
            </div>
          </div> */}

          <div className={styles.type} data-type={type}>
            <img src={getImage()} alt="" />
          </div>
          <button>+</button>
        </div>
      </div>
    </Link>
  );
};

export default CourseTrainingCard;
