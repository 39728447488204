import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import logo from "./img.png";
import axios from "axios";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const PasswordModal = () => {
  const [email, setEmail] = useState("");
  const [passwordAdmin, setPasswordAdmin] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);

  const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value);
  };
const handleEmailChange = (e) => {
  const value = e.target.value;
  setEmail(value);
  if (!validateEmail(value)) {
    setEmailError("Email invalid");
  } else {
    setEmailError(null);
  }
};

const handlePasswordChange = (e) => {
  const value = e.target.value;
  setNewPassword(value);
  if (value !== confirmPassword) {
    setPasswordError("Passwords don't match");
  } else {
    setPasswordError(null);
  }
};

const handleConfirmPasswordChange = (e) => {
  const value = e.target.value;
  setConfirmPassword(value);
  if (newPassword !== value) {
    setConfirmPasswordError("Passwords don't match");
  } else {
    setConfirmPasswordError(null);
  }
};






  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (!validateEmail(email)) {
      setEmailError("Email invalid");
      isValid = false;
    } else {
      setEmailError(null);
    }
  
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords don't match");
      setConfirmPasswordError("Passwords don't match");
      isValid = false;
    } else {
      setPasswordError(null);
      setConfirmPasswordError(null);
    }
    if (!emailError && !passwordError && !confirmPasswordError) {
    try {
      const url = `${process.env.REACT_APP_API}api/Trainer/changepassword`;

      const response = await axios.post(url, {
        email,
        passwordAdmin,
        newPassword,
        confirmPassword,
      });

      if (response.status === 200) {
        setSuccess("Password changed successfully");
        setError(null);
         window.location = "/profile/edit";
      } else {
        setSuccess(null)
        setError("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }}
  };


  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(null); 
      }, 2000); 

      return () => clearTimeout(timer); 
    }
    if (error) {
      const timer = setTimeout(() => {
        setError(null); 
      }, 2000);

      return () => clearTimeout(timer);
    }
    console.log("error ",error)
  }, [success, error]);

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div>
          <h2 className={styles.header}>Reset your password</h2>
          <p className={styles.underline}></p>
        </div>
        <img className={styles.img} src={logo} alt="Logo" />
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.inputGroup}>
          <label className={styles.label}>Email</label>
          <input
            type="text"
            placeholder="Email"
            value={email}
            className={styles.input}
            onChange={handleEmailChange}
            onBlur={handleEmailChange} 
          />   
          {emailError && <span  className={styles.errorHandling}>{emailError}</span>}
        </div>
         
        <div className={styles.inputGroup}>
          <label className={styles.label}>Current password</label>
          <input
            onChange={(e) => setPasswordAdmin(e.target.value)}
            type="password"
            placeholder="Current password"
            className={styles.input}
          />
        </div>
        <div className={styles.inputGroup}>
          <label className={styles.label}>New password</label>
          <input
            type="password"
            placeholder="New Password"
            className={styles.input}
           onChange={handlePasswordChange}
           onBlur={handlePasswordChange}
    />

        </div>
        <div className={styles.inputGroup}>
          <label className={styles.label}>Confirm password</label>
          <input
            type="password"
            placeholder="Confirm Password"
            className={styles.input}
            onChange={handleConfirmPasswordChange}
            onBlur={handleConfirmPasswordChange}
          />
            {confirmPasswordError && <span  className={styles.errorHandling}>
              {confirmPasswordError}</span>}
        </div>
        <button type="submit" className={styles.button}>
          Change password
        </button>

        {success && (
        <div className={styles.successMessage}>
          <FaCheckCircle color="green" />
          {success}
        </div>
      )}
      {error && <div className={styles.errorMessage}>
      <FaExclamationCircle color="red" />{error}</div>}
      </form>
    </div>
  );
};

export default PasswordModal;
