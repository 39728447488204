import React, { memo } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { request } from "../../../core/api/request";
import { useQuiz } from "../../../hooks/QuizContext";
import Button from "../../../shared/components/button";
import { useQuizzes } from "../hooks/useQuizzes";
import styles from "../styles.module.css";

const Quizzes = ({ visibleFrom, hiddenFrom }) => {
  const { id } = useParams();
  const { setQuiz, setCurrentQuestion, setIsFinishButtonDisplay } = useQuiz();
  const { examDetails, hasMore, isKillMistakes, handleLoadMore, navigate } =
    useQuizzes();

  const handleNavigate = () => {
    if (!isKillMistakes) return;
    sessionStorage.clear();
    request.read(`/quiz/generate/killMistakes/${id}`).then((data) => {
      if (data.status === "success") {
        toast.success(data.message);
        return;
      }

      if (data.status === "no_answers") {
        toast.error(data.message);
      }

      if (data.status === "partial_success") {
        const quiz = {
          ...data?.candidatAnswer,
          questions: data?.candidatAnswer?.questions?.map((q) => ({
            ...q,
            bookmarked: false
          }))
        };

        setQuiz(quiz);
        setCurrentQuestion({ id: quiz.questions[0].question, number: 0 });
        setIsFinishButtonDisplay(false);
        navigate(`/quiz/${id}`);
      }
    });
  };

  return (
    <div
      className={styles.suggestions}
      data-hidden-from={hiddenFrom}
      data-visible-from={visibleFrom}
    >
      <Button className={styles.examButton} text="Exam Simulator" />
      {examDetails.map((item, index) => (
        <button
          onClick={() => navigate(`/quiz/details/${item._id}/1`)}
          key={item._id}
          className={styles.quiz}
        >
          <span>
            {item.title} {index + 1}
          </span>
        </button>
      ))}
      {hasMore && (
        <button onClick={handleLoadMore} className={styles.more}>
          ...more
        </button>
      )}
      <button
        onClick={handleNavigate}
        className={`${styles.quiz} ${!isKillMistakes && styles.killMistake}`}
      >
        <span>Kill Mistake</span>
      </button>
    </div>
  );
};

export default memo(Quizzes);
