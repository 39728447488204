import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { request } from "../../../../core/api/request";
import useDebouncedState from "../../../../hooks/useDebouncedState";

const useQuiz = () => {
  const [open, setOpen] = useState(false);
  const [quiz, setQuiz] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useDebouncedState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [searchTerm, setSearchTerm] = useState({ Title: "" });
  const [img, setImg] = useState(null);
  const [searchResults, setSearchResults] = useState({
    data: [],
    isVisible: true
  });

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    numberOfQuestions: "",
    time: "",
    passingScore: "",
    related: "",
    relatedId: ""
  });

  // Fetch search results when related field changes
  useEffect(() => {
    if (formData.related) {
      request.read(`/quiz/related/${formData.related}`).then((data) => {
        setSearchResults({ data, isVisible: true });
      });
    }
  }, [formData.related]);

  // Fetch quiz data when search changes
  useEffect(() => {
    if (!open) setLoading(true);
    request
      .list("/quiz", { limit: 10, page: 1, search })
      .then((data) => {
        setCurrentPage(data.currentPage);
        setTotalPages(data.totalPages);
        setQuiz(data.quizzes);
      })
      .finally(() => setLoading(false));
  }, [search, open, currentPage]);

  // Handle changes in search term
  const handleSearch = (value) => {
    setSearchTerm((prev) => ({ ...prev, Title: value }));
    if (formData.related) {
      request
        .list(`/quiz/related/${formData.related}`, { search: value })
        .then((data) => setSearchResults({ data, isVisible: true }));
    }
  };

  // Handle changes in form input fields
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle related ID selection from search results
  const handleSelectRelatedId = (item) => {
    setSearchTerm(item);
    setFormData({ ...formData, relatedId: item._id });
    setSearchResults({ data: [], isVisible: false });
  };

  // Handle page change for pagination
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Reset form state
  const resetForm = () => {
    setFormData({
      title: "",
      description: "",
      numberOfQuestions: "",
      time: "",
      passingScore: "",
      related: "",
      relatedId: ""
    });
    setImg(null);
    setIsEditMode(false);
    setOpen(false);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!img) {
      toast.error("Please upload a profile picture");
      return;
    }

    const { related, relatedId, ...res } = formData;
    console.log(relatedId);
    const addedData =
      related === "exam-simulator"
        ? { course: relatedId }
        : { [related]: relatedId };
    let data = { ...res, related, ...addedData };

    if (formData.related === "exam-simulator") {
      data.related = "course";
      data.numberOfQuestions = searchTerm.NumberQuestion;
      data.time = searchTerm.DurationQuiz;
    }

    const uploadImg = async (id) => {
      if (!(img instanceof File)) return;
      const formDataProfilePicture = new FormData();
      formDataProfilePicture.append("file", img);

      await request.updateAndUpload(
        `quiz/upload/${id}`,
        formDataProfilePicture
      );
    };

    if (isEditMode && selectedQuiz) {
      request
        .update(`/quiz`, selectedQuiz._id, data)
        .then((data) => {
          uploadImg(data._id);
          setQuiz(quiz.map((q) => (q._id === selectedQuiz._id ? data : q)));
          toast.success("Quiz updated successfully!");
        })
        .catch(() => toast.error("Failed to update quiz."));
    } else {
      request
        .create("/quiz", data)
        .then((data) => {
          uploadImg(data._id);
          setQuiz([...quiz, data]);
          toast.success("Quiz added successfully!");
        })
        .catch(() => toast.error("Failed to add quiz."));
    }

    resetForm();
  };

  // Handle edit click on an existing quiz
  const handleEditClick = (quiz) => {
    setIsEditMode(true);
    setSelectedQuiz(quiz);
    setFormData({
      title: quiz.title,
      description: quiz.description,
      numberOfQuestions: quiz.numberOfQuestions,
      time: quiz.time,
      passingScore: quiz.passingScore,
      related: quiz.related || "",
      relatedId: quiz.relatedId || ""
    });
    setImg(`${process.env.REACT_APP_API}${quiz.image?.filePath}`);
    setSearchResults({ ...searchResults, isVisible: false });
    setOpen(true);
  };

  const cancelForm = () => {
    resetForm();
  };

  return {
    quiz,
    search,
    currentPage,
    totalPages,
    isEditMode,
    selectedQuiz,
    searchTerm,
    img,
    searchResults,
    formData,
    setSearch,
    setSearchTerm,
    setFormData,
    setImg,
    handleSearch,
    handleSelectRelatedId,
    handleChange,
    handleSubmit,
    handleEditClick,
    handlePageChange,
    setOpen,
    open,
    loading,
    cancelForm
  };
};

export default useQuiz;
