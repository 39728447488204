import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useVoucher from "../hooks/useVoucher";
import Loading from "../../../Loading/index";
import ShowVouchersByCourse from "../ShowVouchersByCourse"; // Assurez-vous que ce chemin est correct
import styles from "./styles.module.css";

const ShowVouchers = () => {
  const { courses, loading, fetchVouchersByCourse, fetchCourses } = useVoucher();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentCourseId, setCurrentCourseId] = useState(null);
  const [vouchers, setVouchers] = useState([]);
  const [course, setCourse] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    if (courses) {
      setTotalPages(Math.ceil(courses.length / itemsPerPage));
    }
  }, [courses]);
  const handleViewVouchers = async (courseId) => {
    try {
      setCurrentCourseId(courseId);

      const { course, vouchersDetails } = await fetchVouchersByCourse(courseId);

      if (course && vouchersDetails) {
        setCourse(course);
        setVouchers(vouchersDetails);
      } else {
        console.warn("No data returned for courseId:", courseId);
      }
      fetchCourses();
    } catch (error) {
      console.error("Error in handleViewVouchers:", error);
      setCourse(null);
      setVouchers([]);
    }
  };
  const paginatedCourses = courses.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  return (
    <div className={styles.container}>
      {currentCourseId ? (
        <ShowVouchersByCourse vouchers={vouchers} courses={courses} onBack={() => setCurrentCourseId(null)} />
      ) : (
        <>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table aria-label="courses table">
              <TableHead>
                <TableRow>
                  {["Title", "Available Vouchers", "View"].map((heading) => (
                    <TableCell key={heading} className={styles.tableHeader}>
                      {heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={3} className={styles.loadingCell}>
                      <Loading h="55vh" />
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedCourses.map((course) => (
                    <TableRow key={course._id}>
                      <TableCell>{course.Title}</TableCell>
                      <TableCell>{course.vouchers.length || 0}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => handleViewVouchers(course?._id)}
                          variant="outlined"
                          color="primary"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(courses.length / itemsPerPage)}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            color="primary"
            className={styles.pagination}
          />
        </>
      )}
    </div>
  );
};

export default ShowVouchers;
