import React from "react";
import {   TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Box,
  Typography,
   CircularProgress } from "@mui/material";
import useVoucher from "../hooks/useVoucher";

const AddVoucher = () => {
  const { 
    courses,
    formData, 
    handleChange, 
    submitVoucher, 
    loading, 
    error, 
    success 
  } = useVoucher();

  const isFormValid = formData.link && formData.code && formData.courseId;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 400,
        margin: "auto",
        padding: 2,
        boxShadow: 2,
        borderRadius: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Add Voucher
      </Typography>
      <TextField
        label="Link"
        value={formData.link}
        onChange={(e) => handleChange("link", e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
        required
       
      />
      <TextField
        label="Code"
        value={formData.code}
        onChange={(e) => handleChange("code", e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
        required
      
      />

       <FormControl fullWidth>
        <InputLabel id="course-select-label">Associated Voucher</InputLabel>
        <Select
          labelId="course-select-label"
          name="courseId"
          value={formData.courseId}
          onChange={(e) => handleChange("courseId", e.target.value)}
          required
        >
          <MenuItem value="">
            <em>Select a course</em>
          </MenuItem>
          {courses.map((course) => (
            <MenuItem key={course._id} value={course._id}>
              {course.Title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {loading ? (
        <CircularProgress sx={{ marginTop: 2 }} />
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={submitVoucher}
          disabled={!isFormValid}
          sx={{ marginTop: 2 }}
        >
          Submit
        </Button>
      )}
      {error && (
        <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}
      {success && (
        <Typography color="success" variant="body2" sx={{ marginTop: 2 }}>
          Voucher added successfully!
        </Typography>
      )}
    </Box>
  );
};

export default AddVoucher;
