import React from "react";
import styles from "../styles.module.css";

const Description = ({ examDetails }) => {
  return (
    <div className={styles.details}>
      <div>
        <p className={styles.title} style={{ marginBottom: "30px" }}>
          Description
        </p>
        <p className={styles.description}>{examDetails?.Description}</p>
      </div>

      <div>
        <p className={styles.title} style={{ marginBottom: "30px" }}>
          Goals
        </p>
        <p className={styles.description}>{examDetails?.Goals}</p>
      </div>

      <div>
        <p className={styles.title} style={{ marginBottom: "30px" }}>
          Who Should Attend
        </p>
        <p className={styles.description}>{examDetails?.WhoShouldAttend}</p>
      </div>
    </div>
  );
};

export default Description;
