import { useState, useEffect } from "react";
import { request } from "../../../../core/api/request";
import toast from "react-hot-toast";
const useVoucher = () => {
    const [formData, setFormData] = useState({
        link: "",
        code: "",
        courseId: "",
    });
    const [vouchers, setVouchers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [openChange, setOpenChange] = useState(false);
    const [voucherToUpdate, setVoucherToUpdate] = useState(null);
    const [courses, setCourses] = useState([]);

    useEffect(() => {

        fetchCourses();

    }, []);
    // Récupérer la liste des cours
    const fetchCourses = async () => {

        request
            .list("/courses")
            .then((data) => {

                const filteredVouchers = data.data.filter(course => course.courseType === 'voucher');
                setCourses(filteredVouchers);
            })
            .catch(() => {
                setError("Failed to get courses. Please try again.");

            })
    };
    const handleChange = (field, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    // Récupérer les vouchers
    useEffect(() => {
        fetchVouchers();
    }, []);

    const fetchVouchers = async () => {
        request
            .list("/voucher")
            .then((data) => {
                setVouchers(data.vouchers);

            })
            .catch(() => {
                setError("Failed to get vouchers. Please try again.");

            })

    };

    // Charger les vouchers d'un cours spécifique
    const fetchVouchersByCourse = async (courseId) => {
        try {
            setLoading(true);
            setError("");

            const data = await request.read(`/voucher/${courseId}`);
            const { course, vouchersDetails } = data;
            return { course, vouchersDetails };
        } catch (error) {
            setError("Failed to get vouchers details by course. Please try again.");
            console.error("Error fetching vouchers by course:", error);

            return { course: null, vouchersDetails: [] };
        } finally {
            setLoading(false);
        }
    };

    // Ouvrir le formulaire pour modifier
    const openUpdateForm = (voucher) => {
        setVoucherToUpdate(voucher);
        setFormData(voucher);
        setOpenChange(true);
    };
    const resetForm = () => {
        setFormData({ link: "", code: "" });
        setSuccess(false);
        setError("");
    };
    //ajouter voucher
    const submitVoucher = async () => {
        setLoading(true);
        setError("");
        setSuccess(false);
        request
            .create("/voucher", formData)
            .then(() => {
                setSuccess(true);
                resetForm();
                toast.success("Voucher added successfully!");
            })
            .catch(() => {
                setError("Failed to add voucher. Please try again.");
                toast.error("Failed to add voucher.");
            })
            .finally(() => setLoading(false));

    };
    // Supprimer un voucher
    const deleteVoucher = async (id) => {
        request
            .remove("/voucher", id)
            .then(() => {
                fetchVouchers();
                fetchCourses();
                resetForm();
                setOpenChange(false);
                toast.success("Voucher removed successfully!");
            })
            .catch(() => {
                setError("Failed to remove voucher. Please try again.");
                toast.error("Failed to remove voucher");
            })
    };

    // Modifier un voucher
    const updateVoucher = async (id, formData) => {
        setVoucherToUpdate(formData)
        request
            .update("/voucher", id, formData)
            .then(() => {
                fetchVouchers();
                resetForm();
                setOpenChange(false);
                toast.success("Voucher updated successfully!");
            })
            .catch(() => {
                setError("Failed to update voucher. Please try again.");
                toast.error("Failed to update voucher");
            })

    };

    return {
        vouchers,
        courses,
        fetchVouchers,
        fetchCourses,
        submitVoucher,
        fetchVouchersByCourse,
        deleteVoucher,
        updateVoucher,
        openChange,
        setOpenChange,
        voucherToUpdate,
        openUpdateForm,
        formData,
        handleChange,
        resetForm,
        loading,
        error,
        success
    };
};

export default useVoucher;
