import React, { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "../../core/api/request";
import StandardCourse from "./standard/index";

const Course = () => {
  let { type, id } = useParams();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const paid = user?.CoursesPaid.includes(id) || false;
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    setLoading(true);
    request
      .list("/courses/specific", { id })
      .then((res) => {
        if (res.data.courseType === "exam-simulator" && paid) {
          navigate(`/exam-simulator/${id}`);
        }
      })
      .finally(() => setLoading(false));

    // eslint-disable-next-line
  }, [id]);

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          {/* {paid && type === "course" && <StandardCourse type={type} />} */}
          {!paid && <StandardCourse type={type} />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Course;
