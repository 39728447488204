import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { request } from "../../../core/api/request";

export const useExamDetails = () => {
  const { id } = useParams();
  const [examDetails, setExamDetails] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    request
      .list(`/courses/specific`, { id })
      .then((data) => setExamDetails(data.data))
      .catch(() => toast.error("Something went wrong"))
      .finally(() => setLoading(false));
  }, [id]);

  return { id, loading, examDetails };
};
