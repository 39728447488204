import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import styles from "./styles.module.css";

const VerifyEmail = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState("loading");
  const hasFetched = useRef(false); 

  useEffect(() => {
    const verifyEmail = async () => {
      if (hasFetched.current) return; 

      hasFetched.current = true;
      const params = new URLSearchParams(search);
      const token = params.get("token");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}api/candidat/verify-email?token=${token}`
        );
        console.log("Email verified:", response);

        if (response.status === 201) {
          setStatus("success");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
    
        } else {
          console.log("Verification failed");

        }
      } catch (error) {
        console.error("Error verifying email:", error);
        setStatus("error");
      }
    };

    verifyEmail();
  }, []);

  return (
    <div>
    {status === "loading" && (
      <>
        <div className={styles.verificationMessage}>
          <span>Verifying your email...</span>
        </div>
        <div className={styles.spinner}></div>
      </>
    )}

    {status === "success"  &&(
      <>
        <div className={styles.verificationMessage}>
          <FaCheckCircle className={styles.verificationIcon} />
          <span>Your Email is Verified</span>
        </div>
        <div className={styles.spinner}></div>
        <div className={styles.verificationMessage}>
          <span>Redirecting to login</span>
        </div>
      </>
    )}


    {status === "error" && (
      <>
        <div className={styles.verificationMessage}>
          <FaTimesCircle className={styles.errorIcon} />
          <span>Verification failed. Please try again later.</span>
        </div>
      </>
    )}
  </div>
  );
};

export default VerifyEmail;
