import axios from "axios";
import errorHandler from "./errorHandler";
import successHandler from "./successHandler";

const api = axios.create({
  baseURL: process.env.REACT_APP_API + "api/",
  //  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_API}`,
    "Access-control-request-methods":
      "POST, GET, DELETE, PUT, PATCH, COPY, HEAD, OPTIONS"
  }
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const create = async (entity, jsonData, notifyOnSuccess = false) => {
  try {
    const response = await api.post(`${entity}`, jsonData);
    successHandler(response, {
      notifyOnSuccess: notifyOnSuccess,
      notifyOnFailed: true
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const update = async (
  entity,
  id,
  jsonData,
  method = "PATCH",
  notifyOnSuccess = false
) => {
  try {
    const response = await api.request({
      url: `${entity}/${id}`,
      method,
      data: jsonData
    });
    successHandler(response, {
      notifyOnSuccess: notifyOnSuccess,
      notifyOnFailed: true
    });
    return response.data;
  } catch (error) {
    return errorHandler(error);
  }
};

const read = async (entity, id = "") => {
  try {
    const response = await api.get(`${entity}/${id}`);
    successHandler(response, { notifyOnSuccess: false, notifyOnFailed: true });
    return response.data;
  } catch (error) {
    if (error.response?.status) {
      const unauthorizedStatuses = [401, 403, 404];

      if (unauthorizedStatuses.includes(error.response.status)) {
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");

        if (token && user) {
          localStorage.clear();
          window.location.href = "/home";
          return null;
        }
        return null;
      }
    }
    throw error;
  }
};

const remove = async (entity, id, notifyOnSuccess = false) => {
  try {
    const response = await api.delete(`${entity}/${id}`);
    successHandler(response, {
      notifyOnSuccess: notifyOnSuccess,
      notifyOnFailed: true
    });
    return response.data;
  } catch (error) {
    return errorHandler(error);
  }
};

const list = async (entity, options = {}) => {
  try {
    let query = "?";
    for (const key in options) {
      query += `${key}=${options[key]}&`;
    }
    query = query.slice(0, -1);

    const response = await api.get(`${entity}${query}`);
    successHandler(response, { notifyOnSuccess: false, notifyOnFailed: true });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createAndUpload = async (entity, formData) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_API}api/${entity}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAndUpload = async (entity, formData, method = "PATCH") => {
  try {
    const response = await api.request({
      url: entity,
      data: formData,
      method: method,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const request = {
  create,
  list,
  read,
  remove,
  update,
  createAndUpload,
  updateAndUpload
};
