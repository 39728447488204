import React, { useState } from "react";
import Layout from "./Layout";
import Main from "./Main";

const Admin = () => {
  const [activeSection, setActiveSection] = useState({
    path: "dashboard",
    params: {}
  });

  return (
    <Layout setActiveSection={setActiveSection}>
      <Main
        dashboard={activeSection.path === "dashboard"}
        showCandidats={activeSection.path === "showCandidats"}
        showCourses={activeSection.path === "showCourses"}
        showTrainers={activeSection.path === "showTrainers"}
        showTrainings={activeSection.path === "showTrainings"}
        addCourse={activeSection.path === "addCourse"}
        addTrainer={activeSection.path === "addTrainer"}
        addTraining={activeSection.path === "addTraining"}
        quizzes={activeSection.path === "quizzes"}
        orders={activeSection.path === "orders"}
        questions={activeSection.path === "questions"}
        showCategories={activeSection.path === "showCategories"}
        showSettings={activeSection.path === "showSettings"}
        addVoucher={activeSection.path === "addVoucher"}
        showVouchers={activeSection.path === "showVouchers"}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
      />
    </Layout>
  );
};

export default Admin;
