import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React from "react";
import Input from "../../../../../shared/components/Inputs/Input";
import Select from "../../../../../shared/components/Inputs/Select";
import Button from "../../../../../shared/components/button/index";
import ImgUploadSection from "../../../../ImgUploadSection";
import styles from "./styles.module.css";

const QuizModal = ({
  formData,
  img,
  setImg,
  searchTerm,
  searchResults,
  isEditMode,
  handleSubmit,
  cancelForm,
  handleSearch,
  handleSelectRelatedId,
  handleChange
}) => {
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <form onSubmit={handleSubmit}>
          <ImgUploadSection
            SingleFileChange={(e) => setImg(e.target.files[0])}
            img={img}
            imgSize={100}
            iconBgSize={30}
            iconSize={15}
          />
          <Input
            className={styles.inputForm}
            label="Quiz Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
          <Input
            className={styles.inputForm}
            label="Quiz Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />
          <Select
            name="related"
            label="Quiz Related"
            className={styles.inputForm}
            options={["course", "training", "exam-simulator"]}
            value={formData.related}
            onChange={handleChange}
            required
          />
          {formData.related && (
            <SearchDropdown
              placeholder="Search..."
              searchTerm={searchTerm}
              onSearchChange={handleSearch}
              searchResults={searchResults}
              onSelectResult={handleSelectRelatedId}
            />
          )}
          {formData.related !== "exam-simulator" && (
            <>
              <Input
                className={styles.inputForm}
                type="number"
                name="numberOfQuestions"
                label="Number of questions"
                value={formData.numberOfQuestions}
                onChange={handleChange}
                required
              />
              <Input
                className={styles.inputForm}
                type="number"
                name="time"
                label="Time in minutes"
                value={formData.time}
                onChange={handleChange}
                required
              />
            </>
          )}
          <Input
            className={styles.inputForm}
            type="number"
            name="passingScore"
            label="Passing score"
            value={formData.passingScore}
            onChange={handleChange}
            required
          />

          <div className={styles.buttons}>
            <Button variant="outline" onClick={cancelForm} text="Cancel" />
            <Button
              type="submit"
              text={isEditMode ? "Update Quiz" : "Add Quiz"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const SearchDropdown = ({
  placeholder,
  searchTerm,
  onSearchChange,
  searchResults,
  onSelectResult
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div style={{ position: "relative" }}>
        <Input
          className={styles.inputForm}
          placeholder={placeholder}
          value={searchTerm.Title}
          onChange={(e) => {
            if (!open) setOpen(true);
            onSearchChange(e.target.value);
          }}
        />
        <div
          onClick={() => setOpen(!open)}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "50%",
            right: 10,
            transform: "translateY(-50%)"
          }}
        >
          <ArrowDropDownIcon />
        </div>
      </div>

      {open && (
        <div
          className={
            searchResults?.data?.length > 0 ? styles.searchResultsContainer : ""
          }
        >
          {searchResults.data?.length > 0
            ? searchResults.data.map((item) => (
                <div
                  key={item._id}
                  className={styles.resultItem}
                  onClick={() => onSelectResult(item)}
                >
                  <span className={styles.resultItemText}>{item.Title}</span>
                </div>
              ))
            : searchResults?.isVisible && (
                <div className={styles.noResults}>No results found</div>
              )}
        </div>
      )}
    </div>
  );
};

export default QuizModal;
