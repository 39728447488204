import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "../../../core/api/request";

export const useQuizzes = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [examDetails, setExamDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isKillMistakes, setIsKillMistakes] = useState(false);

  const fetchQuizzes = useCallback(
    (currentPage) => {
      request
        .list(`/quiz/related/${id}/course`, { page: currentPage, limit: 3 })
        .then((data) => {
          setExamDetails((prev) => {
            const existingIds = new Set(prev.map((item) => item._id));
            const newData = data.data.filter(
              (item) => !existingIds.has(item._id)
            );
            return [...prev, ...newData];
          });
          setHasMore(currentPage < data.totalPages);
          setIsKillMistakes(data.isKillMistakes);
        });
    },
    [id]
  );

  useEffect(() => {
    fetchQuizzes(1);
  }, [fetchQuizzes]);

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchQuizzes(nextPage);
  };

  return { examDetails, hasMore, isKillMistakes, handleLoadMore, navigate };
};
