import React from "react";
import useScreenSize from "../../hooks/useScreenSize";
import Loading from "../Loading";
import Nav from "../Nav";
import Description from "./components/Description";
import Quizzes from "./components/Quizzes";
import SuggestionsCards from "./components/SuggestionsCards";
import { useExamDetails } from "./hooks/useExamDetails";
import styles from "./styles.module.css";

const ExamSimulator = () => {
  const { examDetails, loading } = useExamDetails();
  const { isLargeScreen, isMediumScreen, isSmallScreen } = useScreenSize();

  return (
    <React.Fragment>
      <header className={styles.background_container}>
        <div className={styles.appWrapper} style={{ height: "100%" }}>
          <Nav />
          <div className={styles.container}>
            <p className={`${styles.title} ${styles.header}`}>Exam Simulator</p>
          </div>
        </div>
      </header>

      <div className={styles.appWrapper}>
        <div className={styles.container}>
          <div className={styles.breadcrumbContainer}>
            <div className={styles.breadcrumbHeader}>
              <button className={styles.breadcrumbText}>Home</button>
              <img src="/svg/polygon.svg" alt="Breadcrumb separator" />
              <button className={styles.breadcrumbText}>Categories</button>
              <img src="/svg/polygon.svg" alt="Breadcrumb separator" />
              <button className={styles.breadcrumbText}>Details</button>
            </div>
          </div>

          {loading && <Loading h="60vh" />}
          {!loading && (
            <div className={styles.content}>
              <section className={styles.group}>
                <div className={styles.main}>
                  <img
                    className={styles.image}
                    src={
                      examDetails?.Image?.filePath
                        ? `${process.env.REACT_APP_API}${examDetails?.Image?.filePath}`
                        : "https://ditrpindia.com/images/defaultcourse.jpg"
                    }
                    alt="demo"
                  />
                  <p className={styles.text}>{examDetails?.Title}</p>

                  {isSmallScreen && <Quizzes hiddenFrom="mobile" />}

                  <Description examDetails={examDetails} />
                  <SuggestionsCards hiddenFrom="mobile" />
                </div>

                <aside className={styles.aside}>
                  {(isLargeScreen || isMediumScreen) && (
                    <Quizzes visibleFrom="desktop" />
                  )}
                  <SuggestionsCards visibleFrom="desktop" />
                </aside>
              </section>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExamSimulator;
