import {
  AccountCircle as AccountCircleIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  HistoryEdu as HistoryEduIcon,
  OndemandVideo as OndemandVideoIcon,
  Settings as SettingsIcon,
  SupervisorAccount as SupervisorAccountIcon
} from "@mui/icons-material";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import ListAltIcon from "@mui/icons-material/ListAlt";
import QuizIcon from "@mui/icons-material/Quiz";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PrimarySearchAppBar from "./nav";
import styles from "./styles.module.css";

const Layout = ({ children, setActiveSection }) => {
  const [collapseStates, setCollapseStates] = useState({
    candidats: false,
    trainers: false,
    trainings: false,
    courses: false,

    vouchers: false,
  

    quiz: false,
    orders: false

  });

  const handleSetSection = (section) =>
    setActiveSection((prev) => ({ ...prev, path: section }));

  const toggleCollapse = (section) => {
    setCollapseStates((prev) => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  return (
    <React.Fragment>
      <main className={styles.MainSection}>
        <section className={styles.leftSection}>
          <nav className={styles.leftSectionNav}>
            <Link to="/">
              <img
                style={{
                  width: "110px",
                  marginTop: "16px"
                }}
                src="svg/blue_logo.svg"
                alt="U!NOW"
              />
            </Link>
          </nav>
          <div>
            <List
              sx={{ width: "100%", m: 0, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <SectionList
                title="Candidats"
                icon={
                  <AccountCircleIcon sx={{ color: "var(--secondary-color)" }} />
                }
                open={collapseStates.candidats}
                onToggle={() => toggleCollapse("candidats")}
                items={[
                  {
                    label: "Afficher",
                    icon: <ListAltIcon />,
                    onClick: () => handleSetSection("showCandidats")
                  }
                ]}
              />
              <SectionList
                title="Formateurs"
                icon={
                  <SupervisorAccountIcon
                    sx={{ color: "var(--secondary-color)" }}
                  />
                }
                open={collapseStates.trainers}
                onToggle={() => toggleCollapse("trainers")}
                items={[
                  {
                    label: "Afficher",
                    icon: <ListAltIcon />,
                    onClick: () => handleSetSection("showTrainers")
                  },
                  {
                    label: "Ajouter",
                    icon: (
                      <AddCircleOutlineIcon
                        sx={{ color: "var(--secondary-color)" }}
                      />
                    ),
                    onClick: () => handleSetSection("addTrainer")
                  }
                ]}
              />

              <SectionList
                title="Trainings"
                icon={
                  <HistoryEduIcon sx={{ color: "var(--secondary-color)" }} />
                }
                open={collapseStates.trainings}
                onToggle={() => toggleCollapse("trainings")}
                items={[
                  {
                    label: "Afficher",
                    icon: <ListAltIcon />,
                    onClick: () => handleSetSection("showTrainings")
                  },
                  {
                    label: "Ajouter",
                    icon: <AddCircleOutlineIcon />,
                    onClick: () => handleSetSection("addTraining")
                  }
                ]}
              />

              <SectionList
                title="Courses"
                icon={
                  <OndemandVideoIcon sx={{ color: "var(--secondary-color)" }} />
                }
                open={collapseStates.courses}
                onToggle={() => toggleCollapse("courses")}
                items={[
                  {
                    label: "Afficher",
                    icon: <ListAltIcon />,
                    onClick: () => handleSetSection("showCourses")
                  },
                  {
                    label: "Ajouter",
                    icon: <AddCircleOutlineIcon />,
                    onClick: () => handleSetSection("addCourse")
                  }
                ]}
              />

              <ListItemButton onClick={() => handleSetSection("quizzes")}>
                <ListItemIcon>
                  <QuizIcon sx={{ color: "var(--secondary-color)" }} />
                </ListItemIcon>
                <ListItemText primary="Quizzes" />
              </ListItemButton>

              <ListItemButton onClick={() => handleSetSection("orders")}>
                <ListItemIcon>
                  <ShoppingCartCheckoutIcon
                    sx={{ color: "var(--secondary-color)" }}
                  />
                </ListItemIcon>
                <ListItemText primary="Orders" />
              </ListItemButton>

              <ListItemButton
                onClick={() => handleSetSection("showCategories")}
              >
                <ListItemIcon>
                  <ListAltIcon sx={{ color: "var(--secondary-color)" }} />
                </ListItemIcon>
                <ListItemText primary="Categories" />
              </ListItemButton>
              <SectionList
                title="Vouchers"
                icon={<WorkspacePremiumIcon />}
                open={collapseStates.vouchers}
                onToggle={() => toggleCollapse("vouchers")}
                items={[
                  {
                    label: "Afficher",
                    icon: <ListAltIcon />,
                    onClick: () => handleSetSection("showVouchers")
                  },
                  {
                    label: "Ajouter",
                    icon: <AddCircleOutlineIcon />,
                    onClick: () => handleSetSection("addVoucher")
                  }
                ]}
              />

              
              <ListItemButton onClick={() => handleSetSection("settings")}>
                <ListItemIcon>
                  <SettingsIcon sx={{ color: "var(--secondary-color)" }} />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </List>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                sx={{
                  width: "170px",
                  margin: "5px auto",
                  borderRadius: "12px",
                  backgroundColor: "var(--primary-color)"
                }}
                onClick={() => handleSetSection("dashboard")}
                variant="contained"
                startIcon={<LineAxisIcon />}
              >
                Dashboard
              </Button>
            </div>
          </div>
        </section>
        <section className={styles.rightSection}>
          <nav>
            <PrimarySearchAppBar />
          </nav>
          <main className={styles.ComponentContainer}>{children}</main>
        </section>
      </main>
    </React.Fragment>
  );
};

export default Layout;

const SectionList = ({ title, icon, open, onToggle, items }) => (
  <>
    <ListItemButton onClick={onToggle}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
      {open ? (
        <ExpandLess sx={{ color: "var(--secondary-color)" }} />
      ) : (
        <ExpandMore sx={{ color: "var(--secondary-color)" }} />
      )}
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items.map(({ label, icon, onClick }, index) => (
          <ListItemButton key={index} onClick={onClick} sx={{ pl: 4 }}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItemButton>
        ))}
      </List>
    </Collapse>
  </>
);
