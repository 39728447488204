import React, { useState,useEffect } from "react";
import { 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Button, 
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField } from "@mui/material";


const UpdateVoucher = ({ courses, voucher, updateVoucher,openChange, setOpenChange }) => {
  const [formData, setFormData] = useState({
    link: voucher.link || "",
    code: voucher.code || "",
    courseId: voucher.courseId || "",
  });


  useEffect(() => {
    
    if (voucher) {
      setFormData({ link: voucher.link, code: voucher.code, courseId: voucher.courseId });
    }
  }, [voucher]);

  const handleClose = () => {
    setOpenChange(false);
  };

  const handleSubmit = async () => {
    if (!formData.link || !formData.code || !formData.courseId) {
      console.error("Both 'link' and 'code' fields are required.");
      return;
    }
    updateVoucher(voucher._id,formData)
    
  };

  return (
    <Dialog open={openChange} onClose={handleClose}>
      <DialogTitle>Edit Voucher</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Link"
          type="text"
          fullWidth
          value={formData.link}
          onChange={(e) => setFormData({ ...formData, link: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Code"
          type="text"
          fullWidth
          value={formData.code}
          onChange={(e) => setFormData({ ...formData, code: e.target.value })}
        />
         <FormControl fullWidth>
        <InputLabel id="course-select-label">Associated Voucher</InputLabel>
        <Select
          labelId="course-select-label"
          name="courseId"
          value={formData.courseId}
          onChange={(e) => setFormData({ ...formData, courseId: e.target.value })}
          required
        >
          <MenuItem value="">
            <em>Select a course</em>
          </MenuItem>
          {courses.map((course) => (
            <MenuItem key={course._id} value={course._id}>
              {course.Title}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateVoucher;
