import React from "react";
import styles from "../styles.module.css";
import Card from "./Card";

const SuggestionsCards = ({ visibleFrom, hiddenFrom }) => {
  const testData = [
    {
      itemId: 101,
      id: "001",
      title: "Introduction to JavaScript",
      thumbnail: "https://ditrpindia.com/images/defaultcourse.jpg",
      price: 29.99,
      level: "Beginner",
      category: "Programming",
      status: "Available",
      handleDelete: () => console.log("Delete item 001"),
      loading: false,
      handlePaid: () => console.log("Pay for item 001")
    },
    {
      itemId: 102,
      id: "002",
      title: "Advanced CSS Techniques",
      thumbnail: "https://ditrpindia.com/images/defaultcourse.jpg",
      price: 49.99,
      level: "Intermediate",
      category: "Web Design",
      status: "In Cart",
      handleDelete: () => console.log("Delete item 002"),
      loading: false,
      handlePaid: () => console.log("Pay for item 002")
    },
    {
      itemId: 103,
      id: "003",
      title: "Full-Stack Web Development",
      thumbnail: "https://ditrpindia.com/images/defaultcourse.jpg",
      price: 99.99,
      level: "Advanced",
      category: "Development",
      status: "Pending",
      handleDelete: () => console.log("Delete item 003"),
      loading: false,
      handlePaid: () => console.log("Pay for item 003")
    }
  ];

  return (
    <div
      className={styles.suggestions}
      data-hidden-from={hiddenFrom}
      data-visible-from={visibleFrom}
    >
      <p className={styles.topic}>What will be your next learning topic?</p>
      {testData.map((item, index) => (
        <Card key={index} {...item} />
      ))}
    </div>
  );
};

export default SuggestionsCards;
