import React, { useState } from 'react';
import styles from "./styles.module.css";

const Step1 = ({ data, onInputChange }) => {

  const interestsOptions = [
    { id: "sciences", value: "Sciences and Technology", label: "Sciences & Technology" },
    { id: "arts", value: "Arts and Creativity", label: "Arts & Creativity" },
    { id: "history", value: "History and Culture", label: "History & Culture" },
    { id: "languages", value: "Languages and Communication", label: "Languages & Communication" },
    { id: "development", value: "Personal Development", label: "Personal Development" }
  ];

  const [levels, setLevels] = useState(data.levels || {});

  const handleChange = (e) => {
    const { value, checked, type } = e.target;

    if (checked && data.interests.length >= 3) {
      return;
    }
    if (type === "checkbox") {
      const interests = checked
        ? [...data.interests, value]
        : data.interests.filter((interest) => interest !== value);

      const updatedExploreFirst = interests.map((interest) => {
        const existing = data.exploreFirst.find(item => item.interest === interest);
        return existing || { interest, level: '' };
      });

      onInputChange({
        stepPersonalize_1: { ...data, interests, exploreFirst: updatedExploreFirst }
      });
    }
  };

  const handleExploreFirstChange = (interest) => {
    const updatedExploreFirst = data.exploreFirst.map(item =>
      item.interest === interest ? { ...item, interest } : item
    );
    onInputChange({ stepPersonalize_1: { ...data, exploreFirst: updatedExploreFirst } });
  };

  const handleLevelChange = (interest, level) => {
    setLevels({ ...levels, [interest]: level });
    const updatedExploreFirst = data.exploreFirst.map(item =>
      item.interest === interest ? { ...item, level } : item
    );
    onInputChange({ stepPersonalize_1: { ...data, exploreFirst: updatedExploreFirst } });
  };

  return (
    <div className={styles.formcontent}>
      <p>Interest Areas</p>
      <div className={styles.personlizestep}>
        <div className={styles.personlizesquestion}>
          <label>What subjects interest you most? (Select up to three)</label>

          {interestsOptions.map(option => (
            <div className={styles.checkboxGroup} key={option.id}>
              <input
                type="checkbox"
                id={option.id}
                name="interests"
                value={option.value}
                className={styles.hiddenCheckbox}
                checked={data.interests.includes(option.value)}
                onChange={handleChange}
              />
              <label htmlFor={option.id} className={styles.customLabel}>{option.label}</label>
            </div>
          ))}
        </div>

        {Array.isArray(data.interests) && data.interests.length > 0 && (
          <div className={styles.personlizesquestion}>
            <label>Which of the selected areas would you like to explore first?</label>
            {data.interests.map((interest, key) => (
              <div key={key} className={styles.labelContainer}>
                <input
                  type="radio"
                  id={`explore-${interest}`}
                  name="exploreFirst"
                  value={interest}
                  className={styles.hiddenCheckbox}
                  checked={data.exploreFirst && data.exploreFirst.some(item => item.interest === interest && item.level)}
                  onChange={() => handleExploreFirstChange(interest)}
                />
                <label htmlFor={`explore-${interest}`} className={styles.customLabelExplore}>
                  {interest}
                </label>

                <div>
                  <div>
                    <input
                      type="radio"
                      name={`level-${interest}`}
                      className={styles.checkedRadio}
                      value="Beginner"
                      id="Beginner"
                      checked={data.exploreFirst && data.exploreFirst.some(item => item.interest === interest && item.level === "Beginner")}
                      onChange={() => handleLevelChange(interest, "Beginner")}
                    />
                    <label htmlFor="Beginner">Beginner</label>

                  </div>
                  <div>
                    <input
                      type="radio"
                      name={`level-${interest}`}
                      className={styles.checkedRadio}
                      value="Intermediate"
                      id="Intermediate"
                      checked={data.exploreFirst && data.exploreFirst.some(item => item.interest === interest && item.level === "Intermediate")}
                      onChange={() => handleLevelChange(interest, "Intermediate")}
                    />
                    <label htmlFor="Intermediate">Intermediate</label>

                  </div>
                  <div>
                    <input
                      type="radio"
                      name={`level-${interest}`}
                      className={styles.checkedRadio}
                      value="Advanced"
                      id="Advanced"
                      checked={data.exploreFirst && data.exploreFirst.some(item => item.interest === interest && item.level === "Advanced")}
                      onChange={() => handleLevelChange(interest, "Advanced")}
                    />
                    <label htmlFor="Advanced">Advanced</label>

                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Step1;
