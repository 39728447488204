import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SendIcon from "@mui/icons-material/Send";
import { v4 as uuidv4 } from "uuid";
const Details = ({ Course, setOpenChange, openChange }) => {
  const steps = [
    "+3 candidats sont inscrit ",
    "+1 formateur accepte la formation",
    "Confirmation admin",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [course, setCourse] = useState(Course);
  const [notifs, setNotifs] = useState([]);
  const [defaultTrainer, setDefaultTrainer] = useState(null);

  const [countState, setCountState] = useState(0);
  const [candidats, setCandidats]=useState([]);
  
  const [FRSelected, setFRSelected] = React.useState("");
  const [trainerSelected, setTrainerSelected] = React.useState("");
  const [affectedTrainer, setAffectedTrainer] = React.useState("");
  const token = localStorage.getItem("token");
  var count = 0;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
    border: "none",
  };

  const Formateurs =
  notifs.length > 0 &&
  notifs?.map((notif) => {
    if (notif.StatusMandate == "closed" && notif.reponseFormateur=="confirmed" && 
      course.Trainer===notif.trainer && course.state==="confirmed")
      return (
        <li >
          {affectedTrainer.name} {affectedTrainer.subname} : AFFECTED
        </li>
      );
    if (
      notif.StatusMandate == "confirmed" ||
      notif.StatusMandate == "pending" && notif.reponseFormateur=="confirmed"
    )
      return (
        <li>
          {notif.Trainer.name} {notif.Trainer.subname}: {notif.reponseFormateur}
        </li>
      );
  });


const FormateurDropList = Array.isArray(notifs) && notifs.length > 0 
  ? notifs.filter((notif)=>notif.reponseFormateur==="confirmed")   
           .map((notif) => {
      console.log('notif:', notif); 
      
      return (
        <MenuItem
          key={notif._id}
          sx={{ display: "flex", justifyContent: "space-between" }}
          value={notif._id}
        >
          {`${
            notif?.Trainer?.subname?.charAt(0).toUpperCase() +
            notif?.Trainer?.subname?.slice(1)
          } ${notif?.Trainer?.name}`}
          <strong>
            {notif?.prixFormateur.value } {notif?.prixFormateur.currency} 
           
          </strong>
        </MenuItem>
      );
    })
  : null;



  useEffect(() => {
    console.log("course: ", Course);
    setCourse(Course);
  }, [Course]);


  const handleCloseChange = () => {
    setOpenChange(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const config = {
      headers: { authorization: `Bearer ${token}` },
    };
    const url = `${process.env.REACT_APP_API}api/trainings/updateTraining`;
    axios.post(url, course, config).then((res) => {
      handleCloseChange();
    });
  };

  useEffect(() => {
    const loadCandidats = async () => {
      const candidatsList = await fetchCandidats();
      console.log("candidatsList: ", candidatsList);
      setCandidats(candidatsList);
    };

    loadCandidats();
  }, [course]);

  useEffect(() => {
    getNotifTrainerByCourse(course._id);
  }, [course]);

  const getNotifTrainerByCourse = async (id) => {
    const config = {
      headers: { authorization: `Bearer ${token}` },
    };
    const url = `${process.env.REACT_APP_API}api/Trainer/GetNotifTrainerByCourse/${id}`;
    await axios.get(url, config).then((res) => {
      setNotifs(res.data.data.updatedTrainerNotifs);
      setAffectedTrainer(res.data.data.affectedTrainer);
      setDefaultTrainer(res.data.data.defaultTrainer);
    });
  };

  const fetchCandidats = async () => {
    try {
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      const courseId = course._id;
      const url = `${process.env.REACT_APP_API}api/candidat/candidates-paid-courses/${courseId}`;
      console.log("url : ", url);
      const response = await axios.get(url, config);
      console.log("candidatsList: ", response);

      if (response.data && Array.isArray(response.data)) {
        const candidates = response.data;
        return candidates.map((candidat) => (
          <li key={candidat._id}>
            {candidat.candidate.userName}: {candidat.paymentStatus}
          </li>
        ));
      } else {
        console.error("er");
        return <li>No candidates found</li>;
      }
    } catch (error) {
      console.error("Error fetching candidates:", error);
      return [];
    }
  };

  React.useEffect(() => {
    if (notifs.length > 0) {
      notifs.length > 0 &&
        notifs?.map((notif) => {
          if (notif.reponseFormateur === "confirmed") {
            count += 1;
          }
        });
      setCountState(count);
    }
  }, [notifs]);

  React.useEffect(() => {
    const urlId = uuidv4();
    if (Course.enrolled.length >= 2) {
      if (countState >= 1) {
        if (Course.state === "confirmed") {
          setActiveStep(3);
        } else {
          setActiveStep(2);
        }
      }
      if (countState === 0) {
        setActiveStep(1);
      }
    }
  }, [countState]);

  const handleReset = () => {
    setActiveStep(0);
  };







  const handleChange = (event) => {
    setFRSelected(event.target.value);
    notifs.length > 0 &&
      notifs?.map((notif) => {
        if (notif._id === event.target.value) {
          setTrainerSelected(notif.trainer);
        }
      });
  };

  const handleConfirme = () => {
    const config = {
      headers: { authorization: `Bearer ${token}` },
    };

    const urlId = uuidv4();
    const url = `${process.env.REACT_APP_API}api/Trainer/ConfirmNotif`;
    axios
      .post(
        url,
        {
          defaultTrainer: defaultTrainer,
          FRSelected: { Notif: FRSelected, trainer: trainerSelected },
          Course: course,
          urlId: urlId,
        },
        config
      )
      .then((res) => {
        handleCloseChange();
        window.location.reload(true);
      });
  };

  return (
    <Modal
      sx={{ p: 1 }}
      open={openChange}
      onClose={handleCloseChange}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...style,
          width: 800,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "85vh",
        }}
      >
        <div className={styles.ModalComponent}>
          <h2 id="parent-modal-title" className={styles.ModalTitle}>
            Details Training
          </h2>
          <h2 id="parent-modal-title" className={styles.ModalTitle}>
            {course.Title}
          </h2>
          <br />
          <hr />
          <br />

          <Box sx={{ width: "100%", m: 2 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          <div className={styles.table}>
            <div>
              <h2>Candidats</h2>

              <hr />
              <br />

              <ul> {candidats.length > 0 ? candidats : <p>No candidates</p>}</ul>
            </div>
            <div>
              <h2>Trainers</h2>

              <hr />
              <br />
              <ul>{Formateurs.length>0 ? Formateurs : <p>No confirmed Trainers</p>}</ul>
            </div>
            <div>
              <h2>Admin response</h2>

              <hr />
              <br />
              <h4>{course.state}</h4>
            </div>
          </div>
          <Box className={styles.addComment} sx={{ width: "100%", mt: 2 }}>
            <FormControl
              disabled={course.state == "confirmed"}
              sx={
                course.state == "confirmed"
                  ? {
                      "&.Mui-disabled": {
                        pointerEvents: "auto",
                        cursor: "not-allowed",
                      },
                      width: "75%",
                      height: "56px",
                    }
                  : { width: "75%", height: "56px" }
              }
            >
              <InputLabel id="demo-simple-select-label">
                Choisir Un Formateur
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={FRSelected}
                label="Choisir Un Formateur"
                onChange={handleChange}
              >
                {FormateurDropList}
              </Select>
            </FormControl>
            <Button
              disabled={course.state === "confirmed"}
              sx={{ width: "22%", height: "56px", ml: 2 }}
              onClick={handleConfirme}
              variant="contained"
              endIcon={<SendIcon />}
            >
              Comfirm
            </Button>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default Details;
