import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Button,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { request } from "../../../core/api/request";
import useDebouncedState from "../../../hooks/useDebouncedState";
import Input from "../../../shared/components/Inputs/Input";
import Loading from "../../Loading/index";
import styles from "./styles.module.css";

const statusColor = {
  paid: "green",
  unpaid: "red",
  pending: "orange",
  cancelled: "gray"
};

const Question = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useDebouncedState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [orders, setOrders] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    setLoading(true);
    request
      .list("/order/list", { limit: itemsPerPage, page: currentPage, search })
      .then((data) => {
        setOrders(data.orders);
        setTotalPages(data.totalPages);
        setCurrentPage(data.currentPage);
      })
      .finally(() => setLoading(false));
  }, [search, currentPage]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Input
          placeholder="Email..."
          defaultValue={search}
          style={{ maxWidth: "300px", height: "30px" }}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.input}
        />
      </div>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table aria-label="questions table">
          <TableHead>
            <TableRow>
              {[
                "Title",
                "Type",
                "Candidate Name",
                "Preferred Contact",
                "Status"
              ].map((heading) => (
                <TableCell key={heading} className={styles.tableHeader}>
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} className={styles.loadingCell}>
                  <Loading h="55vh" />
                </TableCell>
              </TableRow>
            ) : (
              orders.map((order) => (
                <TableRow key={order._id}>
                  <TableCell>{order.title}</TableCell>
                  <TableCell>{order.type}</TableCell>
                  <TableCell>{order.candidateName}</TableCell>
                  <TableCell>{order.preferredContact}</TableCell>
                  <TableCell className={styles.statusCell}>
                    <div className={styles.statusWrapper}>
                      <span style={{ color: statusColor[order.status] }}>
                        {order.status}
                      </span>
                      <BasicMenu order={order} />
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, value) => setCurrentPage(value)}
        color="primary"
        className={styles.pagination}
      />
    </div>
  );
};

const BasicMenu = ({ order }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (status) => {
    if (order.status === "paid") return;
    if (status) {
      request
        .update("/order", order._id, { status })
        .then(() => toast.success("Order status updated to " + status));
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={`${order.status === "paid" ? styles.disabled : ""}`}
      >
        <BorderColorIcon className={styles.icon} fontSize="small" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null)}
      >
        {["paid", "unpaid", "cancelled"].map((status) => (
          <MenuItem key={status} onClick={() => handleClose(status)}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Question;
