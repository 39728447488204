import React from "react";
import styles from "./styles.module.css";
import UpdateVoucher from "../UpdateVoucher";
import { Typography, Button } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import useVoucher from "../hooks/useVoucher";
const ShowVouchersByCourse = ({ vouchers, courses, onBack }) => {
  const {
    openChange,
    voucherToUpdate,
    setOpenChange,
    deleteVoucher,
    updateVoucher,
    openUpdateForm,
  } = useVoucher();

  const statusColor = {
    available: "green",
    sold: "red",

  };
  // Mapping des vouchers pour affichage
  const vouchersList = vouchers && vouchers.length > 0 ? (
    vouchers.map((voucher) => (
      <div className={styles.CardVoucher} key={voucher._id}>
        <div className={styles.CardBody}>
          <div className={styles.Config}>
            <DeleteOutlineIcon
              className={styles.icons}
              onClick={() => deleteVoucher(voucher._id)} // Assurez-vous que `deleteVoucher` fonctionne
            />
            <EditIcon
              className={styles.icons}
              onClick={() => openUpdateForm(voucher)} // Assurez-vous que `openUpdateForm` fonctionne
            />
          </div>
          <div className={styles.Details}>
            <Typography variant="h6">
              Link: <span>{voucher.link}</span>
            </Typography>
            <Typography variant="h6">
              Code: <span>{voucher.code}</span>
            </Typography>
            <Typography variant="h6">
              Status: <span style={{ color: statusColor[voucher.status] }}>{voucher.status}</span>
            </Typography>
          </div>
        </div>
      </div>
    ))
  ) : (
    <h1>No vouchers available</h1>
  );

  return (
    <React.Fragment>
      {voucherToUpdate && (
        <UpdateVoucher
          courses={courses}
          voucher={voucherToUpdate}
          updateVoucher={updateVoucher}
          openChange={openChange}
          setOpenChange={setOpenChange}
        />
      )}
      <div className={styles.ShowVouchers}>

        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>

        <div className={styles.Cards}>{vouchersList}</div>
      </div>
    </React.Fragment>
  );
};

export default ShowVouchersByCourse;
