import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import React from "react";
import Button from "../../../../shared/components/button";
import useQuestionDetails from "../../hooks/useQuestionDetails";
import styles from "./styles.module.css";

const QuestionDetails = () => {
  const {
    question,
    selectedAnswers,
    isFirstQuestion,
    isFinalQuestion,
    isFinishButtonDisplay,
    handleAnswerChange,
    handleNextQuestion,
    handlePreviousQuestion,
    handleSubmit,
    handleBookmark,
    currentQuestion,
    quiz,
    setOpen,
    open,
    loading
  } = useQuestionDetails();
  return (
    <div className={styles.questions}>
      <div className={styles.group}>
        <p className={styles.title}>
          Question {currentQuestion?.number + 1} of {quiz?.questions?.length}
        </p>

        <BookmarkBorderOutlinedIcon
          onClick={handleBookmark}
          fontSize="large"
          style={{
            cursor: "pointer",
            color: quiz.questions?.[currentQuestion.number].bookmarked
              ? "var(--primary-color)"
              : "#d6d6d6"
          }}
        />
      </div>
      <div className={styles.content}>
        <form onSubmit={handleSubmit}>
          <div className={styles.header}>
            <h3>{question?.questionText}</h3>
            {question?.questionImage && (
              <img
                src={`${process.env.REACT_APP_API}${question.questionImage.filePath}`}
                alt="Question"
                className={styles.questionImage}
              />
            )}
            <p>
              (Choose The
              {question.answersCount === 1
                ? " Best Answer "
                : " " + question.answersCount + " Best Answers "}
              )
            </p>
          </div>
          <div className={styles.choices}>
            {question?.choices?.map((choice) => (
              <div
                onClick={() =>
                  document.getElementById(`input-${choice._id}`).click()
                }
                className={styles.choice}
                key={choice._id}
              >
                <div>
                  <input
                    type={question.answersCount === 1 ? "radio" : "checkbox"}
                    id={`input-${choice._id}`}
                    name="answer"
                    value={choice._id}
                    checked={selectedAnswers.includes(choice._id)}
                    onChange={handleAnswerChange}
                  />
                  {choice.text}
                </div>

                {choice.image && (
                  <img
                    src={`${process.env.REACT_APP_API}${choice.image.filePath}`}
                    alt="Choice"
                    className={styles.choiceImage}
                  />
                )}
              </div>
            ))}
          </div>
          <div className={styles.buttons}>
            {!isFirstQuestion && (
              <Button
                onClick={handlePreviousQuestion}
                varaint="outline"
                text="Previous"
              />
            )}
            <div>
              {!isFinalQuestion && (
                <Button onClick={handleNextQuestion} text="Next" />
              )}
              {(isFinalQuestion || isFinishButtonDisplay) && (
                <Button
                  className={styles.finish}
                  type="submit"
                  loading={loading}
                  text="Finish Test"
                />
              )}
            </div>
          </div>
        </form>
      </div>
      {open.isOpen && (
        <div className={styles.modalContainer}>
          <div className={styles.modal}>
            <p>{open.text}</p>
            <Button onClick={() => setOpen(false)} text="Close" />
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionDetails;
